import React from "react";
import {
  Button,
  Typography,
  Input,
  Checkbox,

} from "@material-tailwind/react";
import { useDispatch } from 'react-redux'
import {
  EnvelopeIcon,
  LockClosedIcon,
  UserIcon
} from "@heroicons/react/24/solid";
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { userRegistration } from '../authActions'
import { setFormIndex } from '../authSlice';

export function SignUpForm(props) {
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().required("Email is required").email("Email is the incorrect format").matches(/@[^.]*\./, "Email is the incorrect format"),
    password: yup.string().required("Password is required").min(6, "Password must be at least 6 characters.")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)[A-Za-z\d\W]{6,}/, {
        message: 'Passwords must have at least one non-alphanumeric, one lowercase and one uppercase character.'
      })
    ,
    retypePassword: yup.string().required("Re-type password is required").oneOf([yup.ref('password'), null], 'Passwords must match')
  });

  const handleFormikSubmit = (values, { setSubmitting }) => {
    dispatch(userRegistration({ values: values, setSubmitting: setSubmitting }))
  }

  return (
    <Formik
      onSubmit={handleFormikSubmit}
      enableReinitialize
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        retypePassword: ''
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, setFieldValue, isSubmitting }) => (
        <form onSubmit={handleSubmit} id="loginForm" noValidate>
          <div className="flex flex-col gap-2 mt-6">
            <Input color="green" label="First name" size="lg"
              icon={<UserIcon className="h-5 w-5 text-base-content/100" />} id="firstName" name="firstName" onChange={handleChange} onBlur={handleBlur} value={values.firstName} error={errors.firstName && touched.firstName}
              success={!errors.firstName && touched.firstName} className="bg-base-100 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10" />
            <ErrorMessage name="firstName">{msg => <div className="text-error text-xs">{msg}</div>}</ErrorMessage>
            <Input color="green" label="Last name" size="lg"
              icon={<UserIcon className="h-5 w-5 text-base-content/100" />} id="lastName" name="lastName" onChange={handleChange} onBlur={handleBlur} value={values.lastName} error={errors.lastName && touched.lastName}
              success={!errors.lastName && touched.lastName} className="bg-base-100 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10" />
            <ErrorMessage name="lastName">{msg => <div className="text-error text-xs">{msg}</div>}</ErrorMessage>
            <Input color="green" label="Email" size="lg"
              icon={<EnvelopeIcon className="h-5 w-5 text-base-content/100" />} id="email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} error={errors.email && touched.email}
              success={!errors.email && touched.email} className="bg-base-100 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10" />
            <ErrorMessage name="email">{msg => <div className="text-error text-xs">{msg}</div>}</ErrorMessage>
            <Input color="green" type="password" label="Password" size="lg" icon={<LockClosedIcon className="h-5 w-5 text-base-content/100" />} id="password" name="password" onChange={handleChange} onBlur={handleBlur} value={values.password}
              error={errors.password && touched.password} success={!errors.password && touched.password} className="bg-base-100 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10" />
            <ErrorMessage name="password">{msg => <div className="text-error text-xs">{msg}</div>}</ErrorMessage>
            <Input color="green" type="password" label="Re-type password" size="lg" icon={<LockClosedIcon className="h-5 w-5 text-base-content/100" />} id="retypePassword" name="retypePassword" onChange={handleChange} onBlur={handleBlur} value={values.retypePassword}
              error={errors.retypePassword && touched.retypePassword} success={!errors.retypePassword && touched.retypePassword} className="bg-base-100 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10" />
            <ErrorMessage name="retypePassword">{msg => <div className="text-error text-xs">{msg}</div>}</ErrorMessage>
            <div className="pt-0 mt-2">
              <Button className="btn btn-success bg-success" fullWidth type="submit" disabled={isSubmitting}>
                Sign Up
              </Button>
            </div>
            <div className="text-center">
              <Button variant="text" className="text-base-content/100" disabled={isSubmitting} onClick={() => dispatch(setFormIndex(0))}>Already have an account?</Button>
            </div>
          </div>
        </form>)}
    </Formik>

  );
}