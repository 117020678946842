import toast from 'react-hot-toast';
import { IconButton } from '@material-tailwind/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import isEmpty from 'utilities/isEmpty';
import React from 'react';
import { Markup } from 'interweave';

export const alertSuccess = (content) => alert(content, "alert-success");

export const alertError = (content) => alert(content, "alert-error");

const alert = (content, className) => toast.custom((t) => {
    return (
        <div className={`${t.visible ? 'animate-enter' : 'animate-leave'} alert max-w-md ${!isEmpty(className) && className}  w-full  shadow-lg pointer-events-auto flex justify-between `}>
            <div className='text-base-content'>
                {getContentForAlert(content)}
            </div>
            <div >
                <IconButton variant="text" size="sm" onClick={() => toast.remove(t.id)}>
                    <XMarkIcon className={'h-6 w-6 text-base-content/80'} />
                </IconButton>
            </div>
        </div>
    )
});

export function getContentForAlert(content) {

    if (typeof content === 'string' || content instanceof String) {
        return (<span>
            <Markup content={content} />
        </span>);
    }
    else if (Array.isArray(content)) {
        return (<ul>{content.map((key, index) => {
            if (typeof content[index] === 'object') {
                let yKeys = Object.keys(content[index]);
                var errors = [];
                (<>{yKeys.map((key, subIndex) => {
                    if (!isEmpty(content[index][key])) {
                        errors.push(content[index][key]);
                    }
                })}</>);
                let errorText = errors.join(', ');
                return <li key={index}><Markup content={errorText} /></li>
            }
            else if (!isEmpty(content[key])) {
                return <li key={index}><Markup content={content[key]} /></li>
            }

        })}</ul>);
    }
    else {
        var contentKeyArray = Object.keys(content);

        return (<ul>{contentKeyArray.map((key, index) => {

            return getContentForAlertSub1(content, key, index)
        })}</ul>);
    }
}

function getContentForAlertSub1(content, key, index) {
    if (!isEmpty(content[key])) {
        if (typeof content[key] === 'string' || content[key] instanceof String) {
            return <li key={index}  ><Markup content={content[key]} /></li>;
        }
        else if (Array.isArray(content[key])) {

            return (content[key].map((subKey, subIndex) => {
                if (isEmpty(subKey))
                    return null;
                return Object.values(subKey).map((subsubKey, subsubindex) => {
                    if (typeof subsubKey === 'object') {
                        let yKeys = Object.keys(subsubKey);
                        return (<>{yKeys.map((key, index) => {

                            return getContentForAlertSub1(subsubKey, key, index);
                        })}</>);
                    }
                    else {
                        return <li key={`${index}_${subsubindex}`}><Markup content={subsubKey} /></li>
                    }

                })

            }));
        }
        else {
            if (!isEmpty(content[key])) {
                var contentKeyArray = Object.keys(content[key]);

                return (contentKeyArray.map((subKey, subIndex) => {
                    if (!isEmpty(content[key][subKey])) {
                        return <li key={`${index}_${subIndex}`} ><Markup content={content[key][subKey]} /></li>
                    }
                }));


            }

        }
    }
}