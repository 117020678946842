import Select from "react-select";
import { DropdownIndicator, ClearIndicator, MultiValueRemove } from "./constants/IconConstants"
import {
  selectInputStyles, valueContainerStyles, singleValueStyles, multiValueStyles,
  multiValueLabelStyles, multiValueRemoveStyles, indicatorsContainerStyles,
  clearIndicatorStyles, indicatorSeparatorStyles, dropdownIndicatorStyles, menuStyles,
  groupHeadingStyles, optionStyles, noOptionsMessageStyles, getControlStyles, getPlaceholderStyles
} from "./constants/StyleConstants"
import isEmpty from '../../../utilities/isEmpty';
import clsx from "clsx";
import React from "react";




export function SelectFromData(props) {
  const { success, error, isDisabled, ...rest } = props

  const successProp = success ?? false;
  const errorProp = error ?? false;

  let controlStyles = getControlStyles(successProp, errorProp, isDisabled);

  return (
    <Select
      menuPosition="fixed"
      menuPlacement="auto"
      isDisabled={isDisabled}
      // isMulti
      // closeMenuOnSelect={false}
      hideSelectedOptions={true}
      unstyled
      styles={{
        input: (base) => ({
          ...base,
          "input:focus": {
            boxShadow: "none",
          },
        }),
        // On mobile, the label will truncate automatically, so we want to
        // override that behaviour.
        multiValueLabel: (base) => ({
          ...base,
          whiteSpace: "normal",
          overflow: "visible",
        }),
        control: (base) => ({
          ...base,
          transition: "none",
        }),
      }}
      components={{ DropdownIndicator: !isDisabled ? DropdownIndicator : null, ClearIndicator: !isDisabled ? ClearIndicator : null, MultiValueRemove: !isDisabled ? MultiValueRemove : null }}
      classNames={{
        control: ({ isFocused }) =>
          clsx(
            isFocused ? controlStyles.focus : controlStyles.nonFocus,
            controlStyles.base,
          ),
        placeholder: () => getPlaceholderStyles(successProp, errorProp),
        input: () => selectInputStyles,
        valueContainer: () => valueContainerStyles,
        singleValue: () => singleValueStyles,
        multiValue: () => multiValueStyles,
        multiValueLabel: () => multiValueLabelStyles,
        multiValueRemove: () => multiValueRemoveStyles,
        indicatorsContainer: () => indicatorsContainerStyles,
        clearIndicator: () => clearIndicatorStyles,
        indicatorSeparator: () => indicatorSeparatorStyles,
        dropdownIndicator: () => dropdownIndicatorStyles,
        menu: () => menuStyles,
        groupHeading: () => groupHeadingStyles,
        option: ({ isFocused, isSelected }) =>
          clsx(
            isFocused && optionStyles.focus,
            isSelected && optionStyles.selected,
            optionStyles.base,
          ),
        noOptionsMessage: () => noOptionsMessageStyles,
      }}
      {...rest}
    />

  )
}