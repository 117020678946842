import { useRouter } from 'next/navigation'

export function useNavigate() {
    const router = useRouter();

    const navigate = (href, newTab) => {
        if (newTab && typeof window !== "undefined") {
            window.open(href);
        }
        else {
            router.push(href);
        }
    }

    return navigate;

}