import * as React from "react";
const GoogleIcon = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 51 51"
    style={{
      enableBackground: "new 0 0 51 51",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {
        "\n\t.st0{fill:#FFFFFF;stroke:#F1485B;stroke-width:0.5;stroke-miterlimit:10;}\n\t.st1{fill:#4285F4;}\n\t.st2{fill:#34A853;}\n\t.st3{fill:#FBBC05;}\n\t.st4{fill:#EB4335;}\n"
      }
    </style>
    <circle className="st0" cx={24.8} cy={26} r={23} />
    <g>
      <path
        className="st1"
        d="M37.2,26.3c0-1.1-0.1-1.9-0.3-2.8H24.2v5h7.4c-0.1,1.2-1,3.1-2.8,4.4v0.2l4,3.1h0.3 C35.7,33.9,37.2,30.4,37.2,26.3"
      />
      <path
        className="st2"
        d="M24.2,39.5c3.6,0,6.7-1.2,8.9-3.3l-4.2-3.3c-1.1,0.8-2.7,1.3-4.7,1.3c-3.6,0-6.6-2.3-7.7-5.6h-0.2l-4.2,3.2 L12,32C14.4,36.5,19,39.5,24.2,39.5"
      />
      <path
        className="st3"
        d="M16.6,28.7c-0.3-0.8-0.4-1.7-0.4-2.7c0-0.9,0.2-1.8,0.4-2.7v-0.2l-4.2-3.3L12.2,20c-0.9,1.8-1.4,3.9-1.4,6 s0.5,4.2,1.4,6L16.6,28.7"
      />
      <path
        className="st4"
        d="M24.2,17.8c2.5,0,4.2,1.1,5.2,2l3.8-3.7c-2.3-2.2-5.4-3.5-9-3.5c-5.3,0-9.8,3-12,7.4l4.4,3.4 C17.7,20.1,20.7,17.8,24.2,17.8"
      />
    </g>
  </svg>
);
export default GoogleIcon;
