import { compile, parse } from 'path-to-regexp';
import qs from 'qs';
import isEmpty from 'utilities/isEmpty';


const compiledCache = {};

class Paths {

    static GeneratePath(rawPath, params = {}, options = { addReturnUrl: false, preserveReturnUrl: false, gotoReturnUrl: false }) {
        var currentQueryStrings = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let hasReturnUrl = !isEmpty(currentQueryStrings["returnUrl"]);

        if (options.gotoReturnUrl && hasReturnUrl) {
            let returnPath = decodeURIComponent(currentQueryStrings["returnUrl"]);
            return returnPath;
        }

        if (options.preserveReturnUrl && hasReturnUrl) {
            params["returnUrl"] = currentQueryStrings["returnUrl"];
        } else if (options.addReturnUrl && !hasReturnUrl) {
            params["returnUrl"] = encodeURIComponent(window.location.pathname + window.location.search);
        }

        let toPath;
        if (compiledCache[rawPath]) {
            toPath = compiledCache[rawPath];
        } else {
            toPath = compile(rawPath);
            compiledCache[rawPath] = toPath;
        }
        const queryParams = { ...params };

        const tokens = parse(rawPath);

        for (var i = 0; i < tokens.length; i++) {
            let token = tokens[i];
            if (!isEmpty(token.name)) {
                delete queryParams[token.name];
            }
        }

        const path = toPath(params, { encode: encodeURIComponent });
        const queryString = qs.stringify(queryParams);
        if (queryString) {
            return `${path}?${queryString}`;
        }
        return path;
    };

    static APPLICATIONS = Object.freeze({
        VENUE: Object.freeze({
            CREATE_VENUE_NEW_APPLICATION: "/create-venue",
            CREATE_VENUE_EXISTING_APPLICATION: "/application/:applicationId/create-venue",
            GOTO_VENUE_STEP: "/application/:applicationId/:activeStep/:id",
        }),
        SPACE: Object.freeze({
            CREATE_SPACE: "/application/:applicationId/edit-spaces/:venueId/create-space",
            GOTO_SPACE_STEP: "/application/:applicationId/edit-spaces/:venueId/:activeStep/:id",
        }),
    });

    static ADMIN = Object.freeze({
        LOCATIONS: Object.freeze({
            LIST: "/admin/locations",
            EDIT: "/admin/locations/edit/:id",
            ADD: "/admin/locations/add"
        }),
        METAS: Object.freeze({
            LIST: "/admin/metas",
            EDIT: "/admin/metas/edit/:id",
            EDIT_TAB: "/admin/metas/edit/:id/:tab",
            ADD: "/admin/metas/add"
        }),
        INDEX_MANAGEMENT: "/admin/index-management",
    });

    static PUBLIC = Object.freeze({
        ROOT: Object.freeze({
            HOME: "/",
            FORBIDDEN: "/forbidden",
            LOGIN: "/login",
        }),
        VSP: "/spaces/:address_slug/:id/:venue_name_slug/:space_name_slug"
    });

    static SEARCH = Object.freeze({

        ROOT: "/search"
    });

    static STAFF = Object.freeze({
        APPLICATIONS: Object.freeze({
            LIST: "/staff/applications",
            EDIT: "/staff/applications/edit/:id",
            ADD: "/staff/applications/add"
        }),
        ENQUIRIES: Object.freeze({
            LIST: "/staff/enquiries",
            EDIT: "/staff/enquiries/edit/:id",
            ADD: "/staff/enquiries/add"
        }),

        REVIEWS: Object.freeze({
            LIST: "/staff/reviews",
            EDIT: "/staff/reviews/edit/:id",
            ADD: "/staff/reviews/add"
        }),
        VENUES: Object.freeze({
            LIST: "/staff/venues",
        }),
        SPACES: Object.freeze({
            LIST: "/staff/spaces",
        }),
        USERS: Object.freeze({
            LIST: "/staff/users",
            EDIT: "/staff/users/edit/:id",
            ADD: "/staff/users/add"
        }),
    });

    static PROFILE = Object.freeze({
        ROOT: "/profile"
    });
}

export default Paths;