import isEmpty from "../../../../utilities/isEmpty";

//or this one
export const selectInputStyles = "text-base-content/100 pl-2 py-0.5 ";
//and this one
export const valueContainerStyles = "p-1 gap-1";
//and this one
export const singleValueStyles = "ml-1 text-[0.875rem]";

export const multiValueStyles =
  "!bg-base-300/50 text-base-content/100 bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5 text-[0.875rem]";
export const multiValueLabelStyles = "text-base-content/100 py-0.5";
export const multiValueRemoveStyles =
  "border bg-base-100 hover:bg-neutral text-white   rounded-md";

export const indicatorsContainerStyles = "p-1 gap-1";
export const clearIndicatorStyles =
  "bg-base-100 hover:bg-neutral rounded-md";
export const indicatorSeparatorStyles = "";
export const dropdownIndicatorStyles =
  "p-1 rounded-md ";
export const menuStyles = "p-1 mt-2 border border-gray-200 bg-base-100 rounded-lg";
export const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
export const optionStyles = {
  base: "hover:cursor-pointer px-3 py-2 rounded ",
  focus: "bg-blue-gray-50 active:bg-blue-gray-50 text-blue-gray-900 active:text-blue-gray-900",
  selected: "after:content-['✔'] after:ml-2 after:text-green-500 ",
};
export const noOptionsMessageStyles =
  "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";

const getSuccessErrorBorderColor = (success, error, defaultBorderColor) => {
  if (success != null && success) {
    return "border-green-500"
  }
  else if (error != null && error) {
    return "border-red-500"
  }
  else {
    return defaultBorderColor;
  }
}

const getSuccessErrorColor = (success, error, defaultBorderColor) => {

  if (!isEmpty(success) && success) {
    return "text-green-500"
  }
  else if (!isEmpty(error) && error) {
    return "text-red-500"
  }
  else {
    return defaultBorderColor;
  }

}



export const getControlStyles = (success, error, disabled) => {
  return {
    base: `${disabled ? "bg-blue-gray-50 border-0 text-blue-gray-400 " : "border bg-base-100"} rounded-lg hover:cursor-pointer !min-h-[44px]`,
    focus: `border border-1 ${getSuccessErrorBorderColor(success, error, "border-green-500")}`,
    nonFocus: `${getSuccessErrorBorderColor(success, error, "border-blue-gray-200")}`,
  };
}

export const getPlaceholderStyles = (success, error,) => {
  return `${getSuccessErrorColor(success, error, "text-blue-gray-400 font-normal")} pl-2 py-0.5 text-[0.875rem]`;
}
