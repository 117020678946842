import { useSelector } from 'react-redux'
import isEmpty from 'utilities/isEmpty';

export function useAuthentication() {
  const auth = useSelector((state) => state.auth);
  const loading = useSelector((state) => state.home.loading);

  return {
    isAuthenticated: auth?.token != null && auth?.permissions?.user != null,
    phoneNumberConfirmed: auth?.token != null && auth?.permissions?.user?.phoneNumberConfirmed,
    user: auth?.permissions?.user,
    userHash: !isEmpty(auth?.permissions?.user?.userHash) ? auth?.permissions?.user?.userHash : auth?.userHash,
    loading: loading
  };
}