import React from 'react';
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';
import {
  BriefcaseIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  InboxIcon,
  PowerIcon,
  MoonIcon,
  SunIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/solid';
import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { AvatarWithText } from './Avatar';
import { useSelector, useDispatch } from 'react-redux';
import { darkModeTheme, lightModeTheme } from '../../utilities/constants';
import { setTheme } from '../../features/home/homeSlice';
import { useAuthentication } from '../../hooks/useAuthentication';
import { logOut } from '../../features/auth/authSlice';

import Paths from 'lib/routes/Paths';
import { useNavigate } from 'hooks/useNavigate';
export function SidebarDefault(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(0);
  const theme = useSelector((state) => state.home.theme);
  const { isAuthenticated } = useAuthentication();
  const navigate = useNavigate();

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const toggleTheme = () => {
    let newTheme = theme === lightModeTheme ? darkModeTheme : lightModeTheme;
    dispatch(setTheme(newTheme));
  };

  const logout = () => {
    props.setOpenNav(false);
    navigate('/');
    dispatch(logOut());
  };

  return (
    <Card className="min-h-[100vh] w-full max-w-[20rem] " color={'transparent'}>
      <AvatarWithText />
      <List
        className={
          'text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10'
        }>
        <Accordion
          open={open === 1}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? 'rotate-180' : ''
                }`}
            />
          }>
          <ListItem className="p-0" selected={open === 1}>
            <AccordionHeader
              onClick={() => handleOpen(1)}
              className="border-b-0 p-3  text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10">
              <ListItemPrefix>
                <BriefcaseIcon className="h-5 w-5 " />
              </ListItemPrefix>
              <Typography className="mr-auto font-medium">Staff</Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="p-0 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10">
              <ListItem
                onClick={() => navigate(Paths.GeneratePath(Paths.STAFF.ENQUIRIES.LIST))}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Enquiries
              </ListItem>
            </List>
          </AccordionBody>
          <AccordionBody className="py-1">
            <List className="p-0 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10">
              <ListItem
                onClick={() => navigate(Paths.GeneratePath(Paths.STAFF.APPLICATIONS.LIST))}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Applications
              </ListItem>
            </List>
          </AccordionBody>
          <AccordionBody className="py-1">
            <List className="p-0 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10">
              <ListItem
                onClick={() => navigate(Paths.GeneratePath(Paths.STAFF.VENUES.LIST))}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Venues
              </ListItem>
            </List>
          </AccordionBody>
          <AccordionBody className="py-1">
            <List className="p-0 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10">
              <ListItem
                onClick={() => navigate(Paths.GeneratePath(Paths.STAFF.SPACES.LIST))}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Spaces
              </ListItem>
            </List>
          </AccordionBody>
          <AccordionBody className="py-1">
            <List className="p-0 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10">
              <ListItem
                onClick={() => navigate(Paths.GeneratePath(Paths.STAFF.REVIEWS.LIST))}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Reviews
              </ListItem>
            </List>
          </AccordionBody>
          <AccordionBody className="py-1">
            <List className="p-0 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10">
              <ListItem
                onClick={() => navigate(Paths.GeneratePath(Paths.STAFF.USERS.LIST))}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Users
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>
        <Accordion
          open={open === 2}
          icon={
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? 'rotate-180' : ''
                }`}
            />
          }>
          <ListItem className="p-0" selected={open === 2}>
            <AccordionHeader
              onClick={() => handleOpen(2)}
              className="border-b-0 p-3 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10">
              <ListItemPrefix>
                <WrenchScrewdriverIcon className="h-5 w-5" />
              </ListItemPrefix>
              <Typography className="mr-auto font-medium">Admin</Typography>
            </AccordionHeader>
          </ListItem>
          <AccordionBody className="py-1">
            <List className="p-0 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10">
              <ListItem
                onClick={() => navigate(Paths.GeneratePath(Paths.ADMIN.LOCATIONS.LIST))}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Locations
              </ListItem>
              <ListItem
                onClick={() => navigate(Paths.GeneratePath(Paths.ADMIN.METAS.LIST))}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Metas
              </ListItem>
              <ListItem
                onClick={() => navigate(Paths.GeneratePath(Paths.ADMIN.INDEX_MANAGEMENT))}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                Index Management
              </ListItem>
            </List>
          </AccordionBody>
        </Accordion>

        {isAuthenticated && (
          <React.Fragment>
            <hr className="my-2 border-blue-gray-50" />
            <ListItem
              onClick={() => navigate(Paths.GeneratePath(Paths.PROFILE.ROOT))}
            >
              <ListItemPrefix>
                <UserCircleIcon className="h-5 w-5" />
              </ListItemPrefix>
              Profile
            </ListItem>
            <ListItem onClick={logout}>
              <ListItemPrefix>
                <PowerIcon className="h-5 w-5" />
              </ListItemPrefix>
              Log Out
            </ListItem>
          </React.Fragment>
        )}

        <hr className="my-2 border-blue-gray-50" />
        <ListItem onClick={() => toggleTheme()}>
          <ListItemPrefix>
            {theme === lightModeTheme ? (
              <MoonIcon className="h-5 w-5" />
            ) : (
              <SunIcon className="h-5 w-5" />
            )}
          </ListItemPrefix>
          {theme === lightModeTheme ? 'Dark mode' : 'Light mode'}
        </ListItem>
      </List>
    </Card>
  );
}
