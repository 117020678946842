import React, { useState, useEffect } from 'react';
import {
  Dialog
} from '@material-tailwind/react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleDialogOpen, setFormIndex } from './authSlice';
import { useAuthentication } from '../../hooks/useAuthentication';

import { AuthCard } from './AuthCard';
import isEmpty from '../../utilities/isEmpty';
import { AuthForms } from 'utilities/constants';

export function AuthDialog() {
  const open = useSelector((state) => state.auth.dialogOpen);
  const formIndex = useSelector((state) => state.auth.formIndex);
  const { isAuthenticated, user, phoneNumberConfirmed } = useAuthentication();

  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(toggleDialogOpen());
  };

  useEffect(() => {
    if (open) {
      if ((isAuthenticated && formIndex === AuthForms.LOGIN) || (phoneNumberConfirmed && formIndex === AuthForms.ENTER_OTP)) {
        dispatch(toggleDialogOpen());
      }
    }
    return function cleanup() { };
  }, [dispatch, open, isAuthenticated, user, formIndex, phoneNumberConfirmed]);

  return (
    <>

      <Dialog
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none max-w-[100%] fixed top-0 bottom-0 md:relative">

        <AuthCard />
      </Dialog>
    </>
  );
}
