import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Typography,
  IconButton,
  Alert,
} from '@material-tailwind/react';
import { XMarkIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useSelector, useDispatch } from 'react-redux';
import { toggleDialogOpen, setFormIndex } from './authSlice';
import { useAuthentication } from '../../hooks/useAuthentication';

import { SignInForm } from './forms/SignInForm';
import { SignUpForm } from './forms/SignUpForm';
import isEmpty from '../../utilities/isEmpty';
import { SendOtpForm } from './forms/SendOtpForm';
import { VerifyOtpForm } from './forms/VerifyOtpForm';
import { AuthErrorAlert } from './controls/AuthErrorAlert';
import { ForgotPassword } from './forms/ForgotPassword';
import { ResetPassword } from './forms/ResetPassword';
import { LoaderWrapper } from 'components/controls/LoaderWrapper';

export function AuthCard(props) {
  const formIndex = useSelector((state) => state.auth.formIndex);

  const [cardLoading, setCardLoading] = useState(true)

  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(toggleDialogOpen());
  };

  useEffect(() => {
    setCardLoading(false);
    return function cleanup() { };
  }, []);

  const isLoginPage = props.isLoginPage ?? false;

  return (
    <>
      <Card
        className={`mx-auto bg-base-100 text-base-content/100 hover:bg-neutral-10 focus:bg-neutral-10 ${!isLoginPage
          ? 'w-full md:max-w-[24rem] rounded-none fixed top-0 bottom-0 md:relative md:rounded-lg'
          : 'm-2 w-full md:max-w-[24rem]'
          }`}>
        <CardBody className="overflow-y-auto">
          {!isLoginPage && (
            <IconButton
              variant="text"
              onClick={handleOpen}
              className={'!absolute !right-1 !top-0'}>
              <XMarkIcon className={'h-6 w-6 text-base-content/100'} />
            </IconButton>
          )}
          <LoaderWrapper loading={cardLoading} >
            <div className={'text-center'}>
              <div className='pb-3'>
                <Typography variant="h5">
                  {(() => {
                    switch (formIndex) {
                      case 0:
                        return 'Log in or create an account';
                      case 1:
                        return 'Create an account with us';
                      case 3:
                        return 'Verify your mobile number';
                      case 4:
                        return 'Enter your OTP';
                      case 5:
                        return 'Forgot your password?';
                      case 6:
                        return 'Reset your password';
                      default:
                        return 'Log in or create an account';
                    }
                  })()}
                </Typography>
              </div>
            </div>
            <AuthErrorAlert />
            {(() => {
              switch (formIndex) {
                case 0:
                  return <SignInForm />;
                case 1:
                  return <SignUpForm />;
                case 3:
                  return <SendOtpForm />;
                case 4:
                  return <VerifyOtpForm />;
                case 5:
                  return <ForgotPassword />;
                case 6:
                  return <ResetPassword />;
                default:
                  return <SignInForm />;
              }
            })()}
            <div className="mt-3">
              <Typography className="text-center text-xs">
                By proceeding, you agree to our Terms of service and Privacy
                Policy
              </Typography>
            </div>
          </LoaderWrapper>
        </CardBody>
      </Card>
    </>
  );
}
