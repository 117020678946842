/* eslint-disable @next/next/no-img-element */
import { useAuthentication } from "../../hooks/useAuthentication";
import isEmpty from "utilities/isEmpty";
import Image from "next/image";
import { MdVerified } from "react-icons/md";
import { BsShieldFillExclamation } from "react-icons/bs";
import { useNavigate } from "hooks/useNavigate";
import Paths from "lib/routes/Paths";

export function AvatarWithText() {
    const { isAuthenticated, user } = useAuthentication();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(Paths.GeneratePath(Paths.PROFILE.ROOT))
    }

    return (
        isAuthenticated && <div className="text-center mt-3 mb-2 space-y-2 cursor-pointer text-base-content" onClick={handleClick}>
            <div className="avatar not-prose w-full relative max-w-[105px]">
                <div className="rounded-full  w-full">
                    {!isEmpty(user?.profileUrl) ? <img src={`${user.profileUrl}?width=400&height=400&rmode=crop&quality=100`} alt="User profile image" /> : <Image src="/blank-profile.svg" height={400} width={400} alt="User profile image" />}
                </div>
            </div>
            <div>
                <span className="text-base font-semibold">{`${user.firstName} ${user.lastName}`}</span>
            </div>
            <div className="flex justify-center items-center">
                <span className="text-xs font-medium">{`${user.email}`}</span><span className="ml-1">{user?.emailConfirmed ? <MdVerified className="h-4 w-4  text-success" /> : <BsShieldFillExclamation className="h-4 w-4 text-error" />}</span>
            </div>
            <div className="flex justify-center items-center">
                <span className="text-xs font-medium">{`${user.phoneNumber}`}</span><span className="ml-1">{user?.phoneNumberConfirmed ? <MdVerified className="h-4 w-4 text-base-content/90 text-success" /> : <BsShieldFillExclamation className="h-4 w-4 text-error" />}</span>
            </div>

        </div>
    );
}